import { Navigate, useLocation } from 'react-router-dom'
import { APICore } from 'helpers/api/apiCore'
import { useUser } from 'hooks'
import React from 'react'

type PrivateRouteProps = {
  component: React.ComponentType
  roles?: string
}

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
  const location = useLocation()
  const [loggedInUser] = useUser()

  const api = new APICore()

  /**
   * not logged in so redirect to login page with the return url
   */
  if (api.isUserAuthenticated() === false) {
    console.log("UA")
    return <Navigate to={'/account/login'} state={{ from: location }} replace />
  }

  return <RouteComponent />
}

export default PrivateRoute
