import axios from 'axios';
import config from '../../config';

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = config.API_URL;


const AUTH_SESSION_KEY = 'hyper_user';

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
  if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  else delete axios.defaults.headers.common.Authorization;
};

const getUserFromSession = () => {
  const user = sessionStorage.getItem(AUTH_SESSION_KEY);
  return user ? (typeof user === 'object' ? user : JSON.parse(user)) : null;
};

export const getToken = () => {
  const userLogged = getUserFromSession();
  return userLogged ? userLogged.token : null;
}

class APICore {
  /**
     * Fetches data from given url
     */
  get = (url: string, params: any) => {
    let response;
    if (params) {
      const queryString = params
        ? Object.keys(params)
          .map((key) => `${key}=${params[key]}`)
          .join('&')
        : '';
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }
    return response;
  };

  getFile = (url: string, params: any) => {
    let response;
    if (params) {
      const queryString = params
        ? Object.keys(params)
          .map((key) => `${key}=${params[key]}`)
          .join('&')
        : '';
      response = axios.get(`${url}?${queryString}`, { responseType: 'blob' });
    } else {
      response = axios.get(`${url}`, { responseType: 'blob' });
    }
    return response;
  };

  getMultiple = (urls: string, params: any) => {
    const reqs = [];
    let queryString = '';
    if (params) {
      queryString = params
        ? Object.keys(params)
          .map((key) => `${key}=${params[key]}`)
          .join('&')
        : '';
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  /**
     * post given data to url
     */
  create = (url: string, data: any) => {
    console.log('url :: ', url);
    return axios.post(url, data);
  };

  /**
     * Updates patch data
     */
  updatePatch = (url: string, data: any) => axios.patch(url, data);

  /**
     * Updates data
     */
  update = (url: string, data: any) => axios.put(url, data);

  /**
     * Deletes data
     */
  delete = (url: string) => axios.delete(url);

  /**
     * post given data to url with file
     */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config: any = {
      headers: {
        ...axios.defaults.headers,
        'content-type': 'multipart/form-data',
      },
    };
    return axios.post(url, formData, config);
  };

  /**
     * post given data to url with file
     */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config: any = {
      headers: {
        ...axios.defaults.headers,
        'content-type': 'multipart/form-data',
      },
    };
    return axios.patch(url, formData, config);
  };

  isUserAuthenticated = () => {
    const user = this.getLoggedInUser();

    if (!user || (user && !user.token)) {
      this.setLoggedInUser(null)
      return false;
    }
    const tokenExpiration: any = (user.tokenExpiration);
    const currentTime = Date.now() / 1000;
    if (tokenExpiration < currentTime) {
      console.warn('access token expired');
      this.setLoggedInUser(null)
      return false;
    }
    return true;
  };

  setLoggedInUser = (session: any) => {
    if (session) sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
    else {
      sessionStorage.removeItem(AUTH_SESSION_KEY);
    }
  };

  /**
     * Returns the logged in user
     */
  getLoggedInUser = () => getUserFromSession();

  setUserInSession = (modifiedUser: any) => {
    const userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
    if (userInfo) {
      const { token, user } = JSON.parse(userInfo);
      this.setLoggedInUser({ token, ...user, ...modifiedUser });
    }
  };
}

/*
Check if token available in session
*/
const user = getUserFromSession();
if (user) {
  const { token } = user;
  if (token) {
    setAuthorization(token);
  }
}

export { APICore, setAuthorization };
