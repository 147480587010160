import React, { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import {
  DefaultLayout,
  VerticalLayout,
  HorizontalLayout,
  DetachedLayout,
  FullLayout,
} from 'layouts'
import PrivateRoute from './PrivateRoute'
import Root from './Root'
import { LayoutTypes } from 'appConstants'
import { useRedux } from 'hooks'


// base route after login
const InformationsDashboard = React.lazy(() => import('pages/dashboard/Informations/InformationsDashboard'))
const DynamicDashboard = React.lazy(() => import('pages/dashboard/Dynamic/DynamicDashboard'))

// Public
const Login = React.lazy(() => import('pages/account/Login'))
const CollusionReportForm = React.lazy(() => import('pages/public/collusionForm'))
const LotteryTicket = React.lazy(() => import('pages/public/lotteryTicket'))
const RankingPifMax = React.lazy(() => import('pages/public/rankingPifMax'))
const SeeAccountData = React.lazy(() => import('pages/account/SeeAccountData'))
const EditAccountData = React.lazy(() => import('pages/account/EditAccountData'))
const Logout = React.lazy(() => import('pages/account/Logout'))
const Register = React.lazy(() => import('pages/account/Register'))
const ActiveUser = React.lazy(() => import('pages/account/ActiveUser'))
const Confirm = React.lazy(() => import('pages/account/Confirm'))
const ForgetPassword = React.lazy(() => import('pages/account/ForgetPassword'))
const ResetPassword = React.lazy(() => import('pages/account/ResetPassword'))
const LockScreen = React.lazy(() => import('pages/account/LockScreen'))
const Users = React.lazy(() => import('pages/account/Users'))

// Ranking
const Ranking = React.lazy(() => import('pages/dashboard/Ranking/Ranking'))

// Players
const PlayersDashboard = React.lazy(() => import('pages/player/dashboard/PlayersDashboard'))
const PlayersInative = React.lazy(() => import('pages/player/inactive/PlayersInactive'))
const PlayersActive = React.lazy(() => import('pages/player/active/PlayersActive'))
const PlayersTags = React.lazy(() => import('pages/player/tags'))

//arrumar essa rota
const Players = React.lazy(() => import('pages/player/list/PlayerList'))

const Ransoms = React.lazy(() => import('pages/app/ransom/RansomList'))
const AppPlayers = React.lazy(() => import('pages/app/players/AppPlayers'))

// Users
const AdminUser = React.lazy(() => import('pages/users/Admin/Admin'))
const AgenteUser = React.lazy(() => import('pages/users/Agente/Agente'))
const ClubUser = React.lazy(() => import('pages/users/Club/Club'))
const LigaUser = React.lazy(() => import('pages/users/Liga/Liga'))

// Clubs
const ClubLottery = React.lazy(() => import('pages/club/lottery'))
const ClubList = React.lazy(() => import('pages/club/list'))
const ClubImportPhone = React.lazy(() => import('pages/club/import/phone'))
const ClubExportGame = React.lazy(() => import('pages/club/export/game'))
const ClubExtra = React.lazy(() => import('pages/club/extra'))
const ClubEdit = React.lazy(() => import('pages/club/edit'))
const Collusion = React.lazy(() => import('pages/club/collusion'))
const ClubsDashboard = React.lazy(() => import('pages/club/dashboard/ClubDashboard'))

// Leagues
const ListLeagues = React.lazy(() => import('pages/league/list/ListLeagues'))
const ClubPayment = React.lazy(() => import('pages/league/club/payment'))
const CollusionReport = React.lazy(() => import('pages/league/reportCollusion'))

// Import Data
const ImportData = React.lazy(() => import('pages/importdata'))
const importDepositFiles = React.lazy(() => import('pages/ImportDeposit'));
const ImportChest = React.lazy(() => import('pages/importChest'))
const ImportTournament = React.lazy(() => import('pages/importTournament'))

// Automatization
const AutomatizationSMS = React.lazy(() => import('pages/automatization'))

// Pages
const ErrorPageNotFound = React.lazy(() => import('pages/error/PageNotFound'))
const ErrorPageNotFoundAlt = React.lazy(() => import('pages/error/PageNotFoundAlt'))
const ServerError = React.lazy(() => import('pages/error/ServerError'))

// Other
const Maintenance = React.lazy(() => import('pages/other/Maintenance'))

const Landing = React.lazy(() => import('pages/landing/'))

const loading = () => <div className=''></div>

type LoadComponentProps = {
  component: React.LazyExoticComponent<() => JSX.Element>
}

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
)

const AllRoutes = () => {
  const { appSelector } = useRedux()

  const { layout } = appSelector((state) => ({
    layout: state.Layout,
  }))

  const getLayout = () => {
    let layoutCls: React.ComponentType = VerticalLayout

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout
        break
      case LayoutTypes.LAYOUT_DETACHED:
        layoutCls = DetachedLayout
        break
      case LayoutTypes.LAYOUT_FULL:
        layoutCls = FullLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  return useRoutes([
    { path: '/', element: <Root /> },
    {
      // public routes
      path: '/',
      element: <DefaultLayout />,
      children: [
        {
          path: 'account',
          children: [
            { path: 'login', element: <LoadComponent component={Login} /> },
            { path: 'register', element: <LoadComponent component={Register} /> },
            { path: 'active', element: <LoadComponent component={ActiveUser} /> },
            { path: 'confirm', element: <LoadComponent component={Confirm} /> },
            { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
            { path: 'reset-password', element: <LoadComponent component={ResetPassword} /> },
            { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
            { path: 'logout', element: <LoadComponent component={Logout} /> },
          ],
        },
        {
          path: 'collusion-report',
          element: <LoadComponent component={CollusionReportForm} />,
        },
        {
          path: 'lottery-ticket',
          element: <LoadComponent component={LotteryTicket} />,
        },
        {
          path: 'ranking-pifmax',
          element: <LoadComponent component={RankingPifMax} />,
        },
        {
          path: 'error-404',
          element: <LoadComponent component={ErrorPageNotFound} />,
        },
        {
          path: 'error-500',
          element: <LoadComponent component={ServerError} />,
        },
        {
          path: 'maintenance',
          element: <LoadComponent component={Maintenance} />,
        },
        {
          path: 'landing',
          element: <LoadComponent component={Landing} />,
        },
      ],
    },
    {
      // auth protected routes
      path: '/',
      element: <PrivateRoute component={Layout} />,
      children: [
        {
          path: 'dashboard',
          children: [
            {
              path: 'informations',
              element: <LoadComponent component={InformationsDashboard} />,
            },
            {
              path: 'dynamic',
              element: <LoadComponent component={DynamicDashboard} />
            }
          ],
        },
        {
          path: 'pages',
          children: [
            {
              path: 'ranking',
              element: <LoadComponent component={Ranking} />,
            },
            {
              path: 'club',
              children: [
                {
                  path: 'dashboard',
                  element: <LoadComponent component={ClubsDashboard} />,
                },
                {
                  path: 'lottery',
                  element: <LoadComponent component={ClubLottery} />,
                },
                {
                  path: 'import',
                  children: [
                    {
                      path: 'phone',
                      element: <LoadComponent component={ClubImportPhone} />,
                    },
                  ],
                },
                {
                  path: 'export',
                  children: [
                    {
                      path: 'game',
                      element: <LoadComponent component={ClubExportGame} />,
                    },
                  ],
                },
                {
                  path: 'list',
                  element: <LoadComponent component={ClubList} />,
                },
                {
                  path: 'extra',
                  element: <LoadComponent component={ClubExtra} />,
                },
                {
                  path: 'edit',
                  element: <LoadComponent component={ClubEdit} />,
                },
                {
                  path: 'collusion',
                  element: <LoadComponent component={Collusion} />,
                },

              ],
            },
            {
              path: 'import-data',
              element: <LoadComponent component={ImportData} />,
            },
            {
              path: 'import-deposit',
              element: <LoadComponent component={importDepositFiles} />,
            },
            {
              path: 'import-chest',
              element: <LoadComponent component={ImportChest} />,
            },
            {
              path: 'import-tournament',
              element: <LoadComponent component={ImportTournament} />,
            },
            {
              path: 'league',
              children: [
                {
                  path: 'list',
                  element: <LoadComponent component={ListLeagues} />,
                },
                {
                  path: 'collusion-report',
                  element: <LoadComponent component={CollusionReport} />,
                },
                {
                  path: 'club',
                  children: [
                    {
                      path: 'payment',
                      element: <LoadComponent component={ClubPayment} />,
                    },
                  ],
                },
              ],
            },
            {
              path: 'error-404-alt',
              element: <LoadComponent component={ErrorPageNotFoundAlt} />,
            },
            {
              path: 'account-data',
              element: <LoadComponent component={SeeAccountData} />,
            },
            {
              path: 'edit-account-data',
              element: <LoadComponent component={EditAccountData} />,
            },
            {
              path: 'player',
              children: [
                {
                  path: 'dashboard',
                  element: <LoadComponent component={PlayersDashboard} />,
                },
                {
                  path: 'inactive',
                  element: <LoadComponent component={PlayersInative} />,
                },
                {
                  path: 'active',
                  element: <LoadComponent component={PlayersActive} />,
                },
                {
                  path: 'list',
                  element: <LoadComponent component={Players} />,
                },
                {
                  path: 'tags',
                  element: <LoadComponent component={PlayersTags} />,
                },
              ],
            },
            {
              path: 'account-users',
              element: <LoadComponent component={Users} />,
            },
            {
              path: 'users',
              children: [
                {
                  path: 'admin',
                  element: <LoadComponent component={AdminUser} />,
                },
                {
                  path: 'club',
                  element: <LoadComponent component={ClubUser} />,
                },
              ],
            },
            {
              path: 'automatization',
              children: [
                {
                  path: 'sms',
                  element: <LoadComponent component={AutomatizationSMS} />,
                },
              ]
            },
            {
              path: 'app',
              children: [
                {
                  path: 'ransom',
                  element: <LoadComponent component={Ransoms} />,
                },
                {
                  path: 'players',
                  element: <LoadComponent component={AppPlayers} />,
                },
              ],
            },
          ],
        },
      ],
    },
  ])
}

export default AllRoutes
