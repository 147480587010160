import React from 'react';
import Routes from 'routes/Routes';
// Para fazer o reset de senha precisa configurar a API para fazer esse direcionamento

import 'assets/scss/Saas.scss';

function App() {
  return <Routes />;
}

export default App;
