import axios from 'axios'
import { APICore } from './apiCore'

const api = new APICore()

async function login(params: { email: string; password: string }) {
  const config: any = {
    headers: {
      'content-type': 'application/json',
    },
  }

  const loginReturn = axios.post(`/api/auth/login`, params, config)

  return loginReturn
}

function logout() {
  // const baseUrl = '/logout/';
  api.setLoggedInUser(null)
  // return api.create(`${baseUrl}`, {});
}

function signup(params: { email: string; password: string }) {
  const baseUrl = '/register/'
  return api.create(`${baseUrl}`, params)
}

function forgotPassword(params: { email: string }) {
  const forgotPasswordReturn = axios.post(`/api/user/forgot-password/${params?.email}`)
  return forgotPasswordReturn
}

function forgotPasswordConfirm(params: { email: string }) {
  const baseUrl = '/password/reset/confirm/'
  return api.create(`${baseUrl}`, params)
}

export { login, logout, signup, forgotPassword, forgotPasswordConfirm }
