export type MenuItemType = {
  key: string
  label: string
  isTitle?: boolean
  icon?: string
  url?: string
  badge?: {
    variant: string
    text: string
  }
  parentKey?: string
  target?: string
  children?: MenuItemType[]
}

const MenuAdmin: MenuItemType[] = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    isTitle: false,
    icon: 'uil-dashboard',
    children: [
      {
        key: 'dashboard-info',
        label: 'Informativo',
        url: '/dashboard/informations',
        parentKey: 'dashboard'
      },
      {
        key: 'dashboard-dynamic',
        label: 'Dinâmico',
        url: '/dashboard/dynamic',
        parentKey: 'dashboard'
      }
    ]
  },
  {
    key: 'players',
    label: 'Jogadores',
    isTitle: false,
    icon: 'uil-shield',
    children: [
      {
        key: 'players-dashboard',
        label: 'Dashboard',
        url: '/pages/player/dashboard',
        parentKey: 'players',
      },
      {
        key: 'players-list',
        label: 'Listar',
        url: '/pages/player/list',
        parentKey: 'players',
      },
      {
        key: 'players-ativo',
        label: 'Ativos',
        url: '/pages/player/active',
        parentKey: 'players',
      },
      {
        key: 'players-inactive',
        label: 'Inativos',
        url: '/pages/player/inactive',
        parentKey: 'players',
      },
      {
        key: 'players-tags',
        label: 'Tags',
        url: '/pages/player/tags',
        parentKey: 'players',
      },
    ],
  },
  {
    key: 'ranking',
    label: 'Ranking',
    isTitle: false,
    url: '/pages/ranking',
    icon: 'uil-trophy',
  },
  {
    key: 'clubs',
    label: 'Clubes',
    isTitle: false,
    icon: 'uil-shield',
    children: [
      {
        key: 'clubs-dashboard',
        label: 'Dashboard',
        url: '/pages/club/dashboard',
        parentKey: 'clubs',
      },
      {
        key: 'clubs-lottery',
        label: 'Sorteios',
        url: '/pages/club/lottery',
        parentKey: 'clubs',
      },
      {
        key: 'clubs-extra',
        label: 'Extras',
        url: '/pages/club/extra',
        parentKey: 'clubs',
      },
      {
        key: 'clubs-list',
        label: 'Integração API',
        url: '/pages/club/list',
        parentKey: 'clubs',
      },
      {
        key: 'page-club-collusion',
        label: 'Collusion',
        url: '/pages/club/collusion',
        parentKey: 'pages-clubs',
      },
      {
        key: 'clubs-export',
        label: 'Exportar Jogos',
        isTitle: false,
        parentKey: 'clubs',
        children: [
          {
            key: 'clubs-export-game',
            label: 'Planilha de Jogos',
            url: '/pages/club/export/game',
            parentKey: 'clubs-export',
          },
        ],
      },
    ],
  },
  {
    key: 'leagues',
    label: 'Ligas',
    isTitle: false,
    icon: 'uil-wallet',
    children: [
      {
        key: 'leagues-payment',
        label: 'Acerto dos Clubes',
        url: '/pages/league/club/payment',
        parentKey: 'leagues',
      },
      {
        key: 'collusion-report',
        label: 'Denúncias de Collusion',
        url: '/pages/league/collusion-report',
        parentKey: 'leagues',
      },
    ],
  },
  {
    key: 'clubs-import',
    label: 'Importar Dados',
    isTitle: false,
    icon: 'uil-down-arrow',
    children: [
      {
        key: 'clubs-import-phone',
        label: 'Celular Jogadores',
        url: '/pages/club/import/phone',
        parentKey: 'clubs-import',
      },
      {
        key: 'import-data',
        label: 'Importar Dados',
        url: '/pages/import-data',
        parentKey: 'clubs-import',
      },
      {
        key: 'import-chest',
        label: 'Importar Baús',
        url: '/pages/import-chest',
        parentKey: 'clubs-import',
      },
      {
        key: 'import-deposit',
        label: 'Importar Depósitos',
        url: '/pages/import-deposit',
        parentKey: 'clubs-import',
      },
      {
        key: 'import-tournament',
        label: 'Importar Torneios',
        url: '/pages/import-tournament',
        parentKey: 'clubs-import',
      },
    ],
  },
  {
    key: 'users',
    label: 'Usuarios',
    isTitle: false,
    icon: 'uil-users-alt',
    children: [
      {
        key: 'admin-user',
        label: 'Admin',
        url: '/pages/users/admin',
        parentKey: 'users',
      },
      {
        key: 'club-user',
        label: 'Clube',
        url: '/pages/users/club',
        parentKey: 'users',
      },
    ],
  },
  {
    key: 'automatization',
    label: 'Automatização',
    isTitle: false,
    icon: 'uil-notes',
    children: [
      {
        key: 'automatization-sms',
        label: 'SMS',
        url: '/pages/automatization/sms',
        parentKey: 'automatization',
      },
    ],
  },
  {
    key: 'app',
    label: 'App',
    isTitle: false,
    icon: 'uil-mobile-android',
    children: [
      {
        key: 'ransom',
        label: 'Resgates',
        url: '/pages/app/ransom',
        parentKey: 'app',
      },
      {
        key: 'appPlayers',
        label: 'Jogadores ativos',
        url: '/pages/app/players',
        parentKey: 'app',
      },
    ],
  },
]

const MenuClub: MenuItemType[] = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    isTitle: false,
    icon: 'uil-dashboard',
    children: [
      {
        key: 'dashboard-info',
        label: 'Informativo',
        url: '/dashboard/informations',
        parentKey: 'dashboard'
      },
      {
        key: 'dashboard-dynamic',
        label: 'Dinâmico',
        url: '/dashboard/dynamic',
        parentKey: 'dashboard'
      }
    ]
  },
  {
    key: 'players',
    label: 'Jogadores',
    isTitle: false,
    icon: 'uil-shield',
    children: [
      {
        key: 'players-list',
        label: 'Listar',
        url: '/pages/player/list',
        parentKey: 'players',
      },
      {
        key: 'players-ativo',
        label: 'Ativos',
        url: '/pages/player/active',
        parentKey: 'players',
      },
      {
        key: 'players-inactive',
        label: 'Inativos',
        url: '/pages/player/inactive',
        parentKey: 'players',
      },
      {
        key: 'players-tags',
        label: 'Tags',
        url: '/pages/player/tags',
        parentKey: 'players',
      },
    ],
  },
  {
    key: 'ranking',
    label: 'Ranking',
    isTitle: false,
    url: '/pages/ranking',
    icon: 'uil-trophy',
  },
  {
    key: 'leagues',
    label: 'Ligas',
    isTitle: false,
    icon: 'uil-wallet',
    children: [
      {
        key: 'leagues-payment',
        label: 'Acerto dos Clubes',
        url: '/pages/league/club/payment',
        parentKey: 'leagues',
      },
    ],
  },
  {
    key: 'clubs-import',
    label: 'Importar Dados',
    isTitle: false,
    icon: 'uil-file',
    children: [
      {
        key: 'clubs-import-phone',
        label: 'Celular Jogadores',
        url: '/pages/club/import/phone',
        parentKey: 'clubs-import',
      },
    ],
  },
]

const MenuClubRansom: MenuItemType[] = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    isTitle: false,
    icon: 'uil-dashboard',
    children: [
      {
        key: 'dashboard-info',
        label: 'Informativo',
        url: '/dashboard/informations',
        parentKey: 'dashboard'
      },
      {
        key: 'dashboard-dynamic',
        label: 'Dinâmico',
        url: '/dashboard/dynamic',
        parentKey: 'dashboard'
      }
    ]
  },
  {
    key: 'players',
    label: 'Jogadores',
    isTitle: false,
    icon: 'uil-shield',
    children: [
      {
        key: 'players-list',
        label: 'Listar',
        url: '/pages/player/list',
        parentKey: 'players',
      },
      {
        key: 'players-ativo',
        label: 'Ativos',
        url: '/pages/player/active',
        parentKey: 'players',
      },
      {
        key: 'players-inactive',
        label: 'Inativos',
        url: '/pages/player/inactive',
        parentKey: 'players',
      },
      {
        key: 'players-tags',
        label: 'Tags',
        url: '/pages/player/tags',
        parentKey: 'players',
      },
    ],
  },
  {
    key: 'ranking',
    label: 'Ranking',
    isTitle: false,
    url: '/pages/ranking',
    icon: 'uil-trophy',
  },
  {
    key: 'leagues',
    label: 'Ligas',
    isTitle: false,
    icon: 'uil-wallet',
    children: [
      {
        key: 'leagues-payment',
        label: 'Acerto dos Clubes',
        url: '/pages/league/club/payment',
        parentKey: 'leagues',
      },
    ],
  },
  {
    key: 'clubs-import',
    label: 'Importar Dados',
    isTitle: false,
    icon: 'uil-file',
    children: [
      {
        key: 'clubs-import-phone',
        label: 'Celular Jogadores',
        url: '/pages/club/import/phone',
        parentKey: 'clubs-import',
      },
    ],
  },
  {
    key: 'app',
    label: 'App',
    isTitle: false,
    icon: 'uil-mobile-android',
    children: [
      {
        key: 'ransom',
        label: 'Resgates',
        url: '/pages/app/ransom',
        parentKey: 'app',
      },
      {
        key: 'appPlayers',
        label: 'Jogadores ativos',
        url: '/pages/app/players',
        parentKey: 'app',
      },
    ],
  },
]

const MenuClubGamb: MenuItemType[] = [
  {
    key: 'players',
    label: 'Jogadores',
    isTitle: false,
    icon: 'uil-shield',
    children: [
      {
        key: 'players-list',
        label: 'Listar',
        url: '/pages/player/list',
        parentKey: 'players',
      },
      {
        key: 'players-ativo',
        label: 'Ativos',
        url: '/pages/player/active',
        parentKey: 'players',
      },
      {
        key: 'players-inactive',
        label: 'Inativos',
        url: '/pages/player/inactive',
        parentKey: 'players',
      },
      {
        key: 'players-tags',
        label: 'Tags',
        url: '/pages/player/tags',
        parentKey: 'players',
      },
    ],
  },
  {
    key: 'ranking',
    label: 'Ranking',
    isTitle: false,
    url: '/pages/ranking',
    icon: 'uil-trophy',
  },
  {
    key: 'clubs-import',
    label: 'Importar Dados',
    isTitle: false,
    icon: 'uil-file',
    children: [
      {
        key: 'clubs-import-phone',
        label: 'Celular Jogadores',
        url: '/pages/club/import/phone',
        parentKey: 'clubs-import',
      },
    ],
  },
  {
    key: 'app',
    label: 'App',
    isTitle: false,
    icon: 'uil-mobile-android',
    children: [
      {
        key: 'ransom',
        label: 'Resgates',
        url: '/pages/app/ransom',
        parentKey: 'app',
      },
      {
        key: 'appPlayers',
        label: 'Jogadores ativos',
        url: '/pages/app/players',
        parentKey: 'app',
      },
    ],
  },
]

const MenuLeague: MenuItemType[] = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    isTitle: false,
    icon: 'uil-dashboard',
    children: [
      {
        key: 'dashboard-info',
        label: 'Informativo',
        url: '/dashboard/informations',
        parentKey: 'dashboard'
      },
      {
        key: 'dashboard-dynamic',
        label: 'Dinâmico',
        url: '/dashboard/dynamic',
        parentKey: 'dashboard'
      }
    ]
  },
  {
    key: 'players',
    label: 'Jogadores',
    isTitle: false,
    icon: 'uil-shield',
    children: [
      {
        key: 'players-list',
        label: 'Listar',
        url: '/pages/player/list',
        parentKey: 'players',
      },
      {
        key: 'players-inactive',
        label: 'Inativos',
        url: '/pages/player/inactive',
        parentKey: 'players',
      },
    ],
  },
  {
    key: 'ranking',
    label: 'Ranking',
    isTitle: false,
    url: '/pages/ranking',
    icon: 'uil-trophy',
  },
  {
    key: 'clubs',
    label: 'Clubes',
    isTitle: false,
    icon: 'uil-shield',
    children: [
      {
        key: 'clubs-dashboard',
        label: 'Desempenho',
        url: '/pages/club/dashboard',
        parentKey: 'clubs',
      },
      {
        key: 'clubs-export',
        label: 'Exportar Planilha',
        url: '/pages/club/export/game',
        parentKey: 'clubs',
      },
    ],
  },
  {
    key: 'leagues',
    label: 'Ligas',
    isTitle: false,
    icon: 'uil-wallet',
    children: [
      {
        key: 'leagues-payment',
        label: 'Acerto dos Clubes',
        url: '/pages/league/club/payment',
        parentKey: 'leagues',
      },
      {
        key: 'collusion-report',
        label: 'Denúncias de Collusion',
        url: '/pages/league/collusion-report',
        parentKey: 'leagues',
      },
    ],
  },
  {
    key: 'clubs-import',
    label: 'Importar Dados',
    isTitle: false,
    icon: 'uil-down-arrow',
    children: [
      {
        key: 'import-data',
        label: 'Importar Dados',
        url: '/pages/import-data',
        parentKey: 'clubs-import',
      },
      {
        key: 'import-chest',
        label: 'Importar Baús',
        url: '/pages/import-chest',
        parentKey: 'clubs-import',
      },
    ],
  },
]

export { MenuAdmin, MenuClub, MenuLeague, MenuClubGamb, MenuClubRansom }
