import React, { BrowserRouter } from 'react-router-dom';
import AllRoutes from './index';

function Routes() {
  return (
    <BrowserRouter>
      <AllRoutes />
    </BrowserRouter>
  );
}

export default Routes;
